import { Controller } from "stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
    this.spinner = document.getElementById('loading-spinner-2');
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
    this.leader.detach(this.fieldTarget);
    this.askai.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '@',
      allowSpaces: false,
      lookup: 'title',
      values: this.fetchUsers.bind(this),
    });

    this.leader = new Tribute({
      trigger: '#',
      allowSpaces: false,
      lookup: 'elements',
      values: this.fetchLead.bind(this),
    });

    this.askai = new Tribute({
      trigger: '*',
      allowSpaces: false,
      lookup: 'elements',
      values: this.fetchResponse.bind(this),
    });

    // Override default behavior to prevent dropdown
    this.askai.showMenu = () => {
      // Prevents the menu from showing
    };

    this.askai.selectItem = (item) => {
      const { elements } = item.original;
      this._insertDirectly(elements);
    };

    this.attachTributes();
  }

  attachTributes() {
    [this.tribute, this.leader].forEach(tribute => {
      tribute.attach(this.fieldTarget);
      tribute.range.pasteHtml = this._pasteHtml.bind(this);
    });

    // Attach `askai` but use the custom behavior for direct insertion
    this.askai.attach(this.fieldTarget);

    // Ensure the custom pasteHtml method is used
    this.askai.range.pasteHtml = this._pasteHtml.bind(this);

    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this));
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => {
        console.error('Error fetching users:', error);
        callback([]);
      });
  }

  fetchResponse(text, callback) {
    this._showSpinner(); // Show spinner before the fetch request

    const currentText = this.editor.getDocument().toString();

    const leadId = this.fieldTarget.dataset.leadId; // Get the lead ID from the data attribute
    const path = `/leads/${leadId}`; // Construct the URL with the lead ID

    fetch(`${path}/propose_next_message.json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
      body: JSON.stringify({ current_text: currentText }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch AI response.");
          }
          return response.json();
        })
        .then((data) => {
          console.log("API Response:", data); // Debug the API response
          this._hideSpinner(); // Hide spinner when data is received

          if (data.elements && data.elements.length > 0) {
            const messageHTML = data.elements[0][1]; // Extract the HTML content
            this._clearActionTextField(); // Clear existing text
            this._insertDirectly(messageHTML); // Insert HTML into the editor
          }

          if (data.subject) {
            this._populateSubjectField(data.subject); // Populate subject field
          }

          // Pass valid data to Tribute.js
          if (data.mentions) {
            callback(data.mentions); // Ensure data.mentions is an array
          } else {
            callback([]); // Send an empty array if mentions are missing
          }
        })
        .catch((error) => {
          console.error("Error fetching response:", error);
          this._hideSpinner(); // Hide spinner in case of error
          callback([]);
        });
  }




  _insertDirectly(html) {
    // Insert HTML content into the Trix editor
    this.editor.loadHTML(html); // Clears the editor and replaces it with the provided HTML
  }
  _clearActionTextField() {
    this.editor.loadHTML(""); // Clears all existing content in the Trix editor
  }

  _populateSubjectField(subject) {
    // Populate the subject input field
    const subjectField = document.querySelector("input[name='contact_log[subject]']");
    if (subjectField) {
      subjectField.value = subject; // Set the value of the subject field
    }
  }



  fetchLead(text, callback) {
    const path = window.location.pathname;
    fetch(`${path}.json?query=${text}`)
      .then(response => response.json())
      .then(lead => callback(lead))
      .catch(error => {
        console.error('Error fetching lead:', error);
        callback([]);
      });
  }

  replaced(e) {
    const mention = e.detail.item.original;
    this._insertDirectly(mention.content);
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos, position]);
    this.editor.deleteInDirection("backward");
  }

  _insertDirectly(html) {
    // Insert HTML content into the Trix editor
    this.editor.insertHTML(html);
  }

  _showSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'block';
    }
  }

  _hideSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'none';
    }
  }
}
